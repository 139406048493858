<template>
    <v-dialog
        v-model="isDrawer"
        fullscreen
        hide-overlay
        scrollable
    >
        <div>
            <v-sheet
                color="secondary darken-4"
                height="50"
                class="d-flex align-center px-3"
            >
                <v-btn
                    icon
                    color="white"
                    @click="isDrawer = false"
                >
                    <w-icon value="COM_BACK"/>
                </v-btn>
                <div
                    v-if="isTitle"
                    class="white--text pl-3 text-truncate"
                >
                    {{ title }}
                </div>
                <v-spacer></v-spacer>
                <div class="pl-3">
                    <v-btn
                        v-if="download"
                        icon
                        color="white"
                        :href="download"
                        class="mr-2"
                    >
                        <w-icon value="DOWNLOAD"/>
                    </v-btn>
                    <v-btn
                        v-if="href"
                        icon
                        color="white"
                        :href="href"
                        target="_blank"
                    >
                        <w-icon value="OPEN_IN_NEW"/>
                    </v-btn>
                </div>
            </v-sheet>
            <v-sheet
                color="rgba(0,0,0,0.8)"
                width="100%"
                height="calc(100vh - 50px)"
                @click="$emit('input', false)"
            >
                <v-sheet
                    tile
                    :max-width="width"
                    height="100%"
                    color="transparent"
                    class="mx-auto px-4"
                >
                    <slot></slot>
                </v-sheet>
            </v-sheet>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'MediaDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        href: {
            type: String,
            default: undefined
        },
        download: {
            type: String,
            default: undefined
        },
        width: {
            type: [String, Number],
            default: 768
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isTitle() {
            return this.title !== undefined || this.$slots.title
        },
        alignCenter() {
            const alignment = ['youtube', 'file']

            return alignment.includes(this.type)
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag
        },
        isDrawer(flag) {
            this.$emit('input', flag)
        }
    },
    data() {
        return {
            isDrawer: false
        }
    }
}
</script>

<style lang=scss>

</style>
